





















































































































































































































































































































































.rma-history {
    a {
        text-decoration: none;
    }
    .v-data-table th[role='columnheader'] {
        white-space: nowrap;
    }
}
